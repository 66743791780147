jQuery(function($){
	$(".tax-links").hover(function(){
		$(this).find("ul").show();
	},function(){
		$(this).find("ul").fadeOut('fast');
	});


/*

var html = '';

  $.magnificPopup.open({
      items: {
        src: html,
        type: 'inline'
      }
    });

*/

/*
		$("#content .zebra").infinitescroll({
			behavior     : 'twitter',
			    bufferPx     : 600,
			    debug      : false,
			    navSelector  : '#nav-below',
			    nextSelector : '.nav-previous a', // selector for the NEXT link (to page 2)
			    itemSelector : '#content .zebra > li', // selector for all items you'll retrieve
			loading: {
				finishedMsg: 'No more pages to load.',
				//img: '/wp-content/themes/MEC/images/bx_loader.gif',
				msgText: ""
			}
		},
		// call Isotope as a callback
		function( newElements ) {
			//analytics
		});
*/

  function burger() {
    var $burger = $("#burger");
    var $menu = $("#sidebar-container");
    $burger.click(function () {

      if ($burger.hasClass("active")) {
          $menu.removeClass("close");
      }
      
      $menu.toggleClass("active");
      $burger.toggleClass("close");
    });
    
    /*
    $("#arc-landing-menu ul a").click(function(e){
      e.preventDefault();
      
      if ($burger.hasClass("active")) {
        $menu.removeClass("close");
      }
      
      $menu.toggleClass("active");
      $burger.toggleClass("close");
      
      
      var targetUrl = $(this).attr("href");
      scrollToHash(targetUrl);
    });
    */
  }
  burger();





});